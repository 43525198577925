<template>
  <CRow>
    <CCol v-for="(image, index) in images" col="12" lg="3" style="padding:0">
      <div style="position: relative">
        <!-- お仕事の写真 -->
        <div>
          <CImg
            shape="rounded"
            style="width:100%; height:auto"
            :src="mainPicture(image)"/>
        </div>
        <!-- 画像をアップした人のアイコン画像 -->
        <div style="position: absolute; top:-10px;left:-10px">
          <CImg
            width="25%"
            thumbnail
            shape="rounded-circle"
            :src="pictureOwner(image)"/>
        </div>
        <!-- 画像を削除するときの X ボタン -->
        <div v-if="showDeleteButton(image)" style="position: absolute; top:5px;right:10px; cursor: pointer">
          <div @click="deletePicture(index)">
            <CIcon style="color:grey" :content="$options.x_circle"/>
          </div>
        </div>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import Comm from "@/views/v1/lib/Comm";
import {cilXCircle} from '@coreui/icons'

export default {
  name: "ShowPicture",
  mixins: [Comm],/* ここでミックスインを追加 */
  x_circle: cilXCircle,
  components: {},
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  computed: {
    showDeleteButton: function(){
      return function(item){
        if(item['user_id'] === this.$store.state.user_id){
          return true
        }else {
          return false
        }
      }
    },
    mainPicture: function (){
      return function (item){
        return this._get_job_image(item.job_id, item.user_id, item.name)
      }
    },
    pictureOwner: function(){
      return function(item){
        return this._get_avatar(item.user_id)
      }
    },
  },
  methods: {
    async deletePicture(index) {
      const item = {
        id: this.images[index].id,
        job_id: this.images[index].job_id,
        active: 0
      }
      const response = await this._imageUpdate(item)
      if(response === true){
        window.alert("画像を削除しました！")
      }else{
        window.alert("画像の削除が失敗しました。。")
      }
      this.$emit("update:show", false)
    },
  },
  mounted() {
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>
