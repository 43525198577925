<template>
  <div>
    <CButton v-if="false" color="success" @click="showProps">CreateJobコンポーネントのボタン</CButton>
    <CRow style="width:100%">
      <CCol lg="7" sm="11">
        <label style="">
          <span v-if="job_id">No. {{job_id}}</span>
          お仕事タイトル *
        </label>
        <CInput
          size=""
          :disabled="is_disabled"
          description="お仕事タイトルは一覧画面に表示されます。"
          v-bind:value="title"
          v-on:change="$emit('update:title', $event)"
        />
      </CCol>
      <CCol
        v-if="job_id"
        lg="2" col="6" class="text-right offset-lg-1" style="padding-top: 29px">
        <CButton
          variant="outline"
          color="dark"
          @click="deleteJob"
        >削除
        </CButton>
      </CCol>
    </CRow>
    <CRow>
      <CCol lg="10">
        <label style="">
          お仕事詳細 *
        </label>
        <CTextarea
          style=""
          :disabled="is_disabled"
          v-bind:value="job_detail"
          v-on:change="$emit('update:job_detail', $event)"
          description="お仕事詳細はなるべく細かく依頼事項を書いてください。"
          rows="3"
        />
      </CCol>
      <!-- リンクを挿入するところ -->
      <CCol lg="10">
        <CRow v-for="(link, index) in links">
          <CCol lg="3">
            <CInput
              type="input"
              :style="toBeDelete(link)"
              v-bind:value="links[index].title"
              v-on:change="updateLinkTitle($event, index)"
              :disabled="is_disabled"
            />
          </CCol>
          <CCol lg="9">
            <CInput
              placeholder="http://..."
              :style="toBeDelete(link)"
              v-bind:value="links[index].href"
              v-on:change="updateLinkHref($event, index)"
              :description="getDescribe(link)"
              :disabled="is_disabled"
            >
              <template #append>
                <CButton
                  @click="addLink(index)"
                  variant="outline"
                  color="secondary">{{addOrDelete(index)}}</CButton>
              </template>
            </CInput>
          </CCol>
        </CRow>
      </CCol>
      <CCol lg="10">
        <CRow>
          <CCol lg="4" col="6">
            <label style="">
              募集開始日 *
            </label>
            <CInput
              type="date"
              :disabled="is_disabled"
              v-bind:value="start_date"
              v-on:change="$emit('update:start_date', $event)"
            />
          </CCol>
          <CCol lg="4" col="6">
            <label style="">
              仕事開始日 *
            </label>
            <CInput
              type="date"
              :disabled="is_disabled"
              v-bind:value="end_date"
              v-on:change="$emit('update:end_date', $event)"
            />
          </CCol>
          <CCol lg="4" col="6">
            <label style="">
              仕事終了日 *
            </label>
            <CInput
              type="date"
              :disabled="is_disabled"
              v-bind:value="finish_date"
              v-on:change="$emit('update:finish_date', $event)"
            />
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <CRow>
      <CCol lg="3" col="6">
        <label style="">
          種別 *
        </label>
        <CSelect
          :disabled="is_disabled"
          :options="getStatusOptions"
          v-bind:value="status"
          v-on:change="$emit('update:status', $event)"
        />
      </CCol>
      <CCol lg="3" col="6">
        <label style="">
          カテゴリ *
        </label>
        <CSelect
          :disabled="is_disabled"
          :options="$store.state.job_categories"
          v-bind:value="category_id"
          v-on:change="$emit('update:category_id', $event)"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol lg="4" col="6">
        <label style="">
          価格 *
        </label>
        <CInput
          :disabled="is_disabled"
          placeholder="例) ¥1500"
          v-bind:value="price"
          v-on:change="$emit('update:price', $event)"
        >
          <template #prepend-content>
            <CIcon name="cil-yen"/>
          </template>
        </CInput>
      </CCol>
      <CCol v-if="false">
        <CCol style="cursor: pointer">
          <CIcon :height="42" :content="$options.line" style="color: #16c464"/></CCol>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Comm from '@/views/v1/lib/Comm.js'
import {cibLine} from '@coreui/icons'

export default {
  name: "CreateJob",
  line: cibLine,
  mixins: [Comm],/* ここでミックスインを追加 */
  components: {
  },
  computed: {
    getDescribe: function(){
      return function(value){
        if(value["link_type"] === -1){
          return "削除します"
        }else{
          return "お仕事の内容を説明するうえで参考になるページのリンクを入れてください。"
        }
      }
    },
    toBeDelete: function(){
      return function(value){
        if(value["link_type"] === -1){
          return "border: solid 1px red"
        }else{
          return
        }
      }
    },
    addOrDelete: function(){
      return function(value){
        const num = this.links.length - 1
        if(value === num){
          return "追加"
        }
        return "削除"
      }

    },
    getStatusOptions: function () {
      return this.statusOptions.filter(item => item.value === 1 || item.value === 2)
    },
  },
  watch: {
  },
  props: {
    job_id: [String, Number],
    title: String,
    start_date: {
      type: String,
      default: ''
    },
    end_date: {
      type: String,
      default: ''
    },
    finish_date: {
      type: String,
      default: ''
    },
    client_id: {
      type: [String, Number],
    },
    price: [String, Number],
    status: {
      type: [String, Number],
      default: 1
    },
    job_detail: String,
    worker_id: {
      type: [String, Number],
      default: 0
    },
    category_id: {
      type: [String, Number],
      default: 1
    },
    links: {
      type: Array,
      default: () => [{title: '参考ページ', href: ''}]
    },
    is_disabled: {
      type: Boolean,
      required: false
    }
  },
  setup() {
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  methods: {
    updateLinkTitle(e, index){
      this.links[index]['title'] = e
      this.$emit('update:links', this.links)
    },
    updateLinkHref(e, index){
      this.links[index]['href'] = e
      this.$emit('update:links', this.links)
    },
    addLink(index){
      this.$emit('update:addLinks', index)
    },
    async deleteJob() {
      if(this.job_id === undefined || this.job_id === ''){
        return
      }
      const item = {
        id: this.job_id,
        status: -1,
      }
      await this._updateItem(item)
      await this.$router.push({name: 'ListJobs'})
    },
    showProps() {
      console.log(this.$props)
    }
  }
}
</script>

<style scoped>

</style>
