<template>
  <div>
    <CButton v-if="false" color="success" @click="showProps">OfferJobコンポーネントのボタン</CButton>
    <CRow>
      <CCol lg="7">
        <label>
          お仕事の内容、オファー価格について相談する
        </label>
        <CTextarea
          rows="3"
          v-bind:value="message"
          v-on:change="$emit('update:message', $event)"
          description="お仕事に対する要望がありましたら記載してください。"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol lg="12">
        <CInputRadioGroup
          class="col-sm-9"
          :options="[
            {value:'1', label:'お仕事に応募する'},
            {value:'2', label:'質問する'}
            ]"
          custom
          inline
          v-bind:checked="type"
          v-on:update:checked="$emit('update:type', $event)"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "ViewJob",
  components: {},
  props: {
    message: String,
    type: String
  },
  setup() {
  },
  mounted() {
  },
  data() {
    return {}
  },
  methods: {
    showProps(){
      console.log(this.$props)
    }
  }
}
</script>

<style scoped>

</style>
