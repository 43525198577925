<template>
  <div>
    <CButton v-if="false" @click="showItem">view.vueのボタンSHOW</CButton>
    <CCard style="width:100%">
      <CCardHeader>
        <div class="card-header-actions">
          <div style="cursor:pointer" @click="$store.state.showMemberList = !$store.state.showMemberList">
            <CIcon :name="`cil-chevron-${$store.state.showMemberList ? 'bottom' : 'top'}`"/>
          </div>
        </div>
      </CCardHeader>
      <CCardBody style="width:100%">
        <CRow>
          <CCol lg="3">
            <my-logo/>
          </CCol>
          <CCol lg="9">
            <create-job
              v-bind:is_disabled="true"
              v-bind:title="title"
              v-bind:job_detail="job_detail"
              v-bind:start_date="start_date"
              v-bind:end_date="end_date"
              v-bind:finish_date="finish_date"
              v-bind:price="price"
              v-bind:status="status"
              v-bind:worker_id="worker_id"
              v-bind:category_id="category_id"
              v-bind:client_id="client_id"
              v-bind:images="images"
            />
            <offer-job
              v-bind:message.sync="message"
              v-bind:type="type"
              v-on:update:type="type = $event"
            />
          </CCol>
          <CCol col="12" style="padding: 20px 35px 10px 35px">
            <show-picture v-if="images.length > 0" :images="images"/>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <CRow>
          <CCol class="col-lg-2">
            <CButton
              style="width:100%"
              variant="outline"
              @click="moveToListJobs"
              size="sm"
              color="warning"
            >キャンセル
            </CButton>
          </CCol>
          <CCol class="col-lg-2 offset-lg-8">
            <CButton
              style="width:100%"
              variant="outline"
              @click="createJob"
              size="sm"
              color="primary"
            >送信
            </CButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import Comm from '@/views/v1/lib/Comm.js'
import CreateJob from "@/views/v1/components/CreateJob"
import OfferJob from "@/views/v1/components/OfferJob"
import MyLogo from "@/views/v1/components/MyLogo"
import ShowPicture from "@/views/v1/components/ShowPicture";

export default {
  name: "ViewJob",
  mixins: [Comm],
  props: {
    id: [Number, String],
    title: String,
    start_date: String,
    end_date: String,
    finish_date: String,
    client_id: [String, Number],
    price: [String, Number],
    status: [String, Number],
    job_detail: String,
    worker_id: [String, Number],
    category_id: [String, Number],
    images: Array,
  },
  computed: {},
  components: {
    "create-job": CreateJob,
    "offer-job": OfferJob,
    "my-logo": MyLogo,
    "show-picture": ShowPicture
  },
  data() {
    return {
      message: '一生懸命頑張ります！ぜひ私にやらせてくださいm(_ _)m',
      type: '1'
    }
  },
  methods: {
    moveToListJobs() {
      this.$router.push({name: 'ListJobs'})
    },
    showEvent(e) {
      console.log(e)
    },
    showItem() {
      console.log("props are ", this.$props)
      console.log("data are ", this.$data)
    },
    async createJob() {
      console.log(this.message, this.type)
      console.log("create-job")
      const item = {
        job_id: this.id,
        family_id: this.$store.state.family_id,
        worker_id: this.user_id,
        offer_type: this.type,
        offer_text: this.message
      }
      const response = await this._createOffer(item)
      console.log(response)
      this.$router.push({name: 'ListJobs'})
    }
  }
}
</script>

<style scoped>
</style>
